import React, { Component, Fragment } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Button } from '@mui/material';
import moment from 'moment';

import ExcelIcon from '../../_img/excel.svg';
import ExportModal from './ExportModal';

export const styles = theme => createStyles({
    button: {
        // marginRight: 20,
        // marginBottom: 8
    },
})

class ExportButton extends Component {
    state = {
        showDialog: false,
        fileName: "Отчет"
    };

    openExport = async () => {
        const { formValues, reports } = this.props;
        const report = reports.find( r => r.id === formValues.report);
        let fileName = `${moment(formValues.startDate).format("DD.MM.YYYY")}  - ${moment(formValues.endDate).format("DD.MM.YYYY")}`
        if(report){
            fileName = `${report.name} ${fileName}`;
        }
        this.setState({
            showDialog: true,
            fileName
        })
    }

    handleClose = () => {
        this.setState({ showDialog: false })
    }

    render() {
        const {classes, formValues, isLoading} = this.props;
        const { showDialog,fileName } = this.state;
        return (
            <Fragment>
                <Button    
                    className={classes.button}  
                    color="secondary" 
                    size="small"
                    variant="contained"
                    endIcon={<img src={ExcelIcon} alt="excel"/>}
                    onClick={this.openExport} 
                    disabled={isLoading}
                >
                    Экспорт в Excel
                </Button>
                <ExportModal handleCloseClick={this.handleClose} formValues={formValues} showDialog={showDialog} fileName={fileName}/>
            </Fragment>
        );
    }
}


const mapStateToProps = (state, props) => {
    const { fetch: { loading } } = state;
    var recordLiveValues = getFormValues('filterForm')(state);
    return { 
        formValues: recordLiveValues,
        isLoading: loading>0 ? true : false
    };
};


export default compose(
    withStyles(styles),
    connect(mapStateToProps)
)(ExportButton);
