import React from 'react';
import { render } from 'react-dom';
import {  Provider  } from 'react-redux';
import { Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ruRU } from '@material-ui/core/locale';

import { Notifier, Confirm, Auth, Reset } from './_Components'

import { history, store } from './_utils';

import './index.css';
import './player.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const theme = createTheme({
    
    typography: {
        fontFamily: 'Montserrat',
        fontStyle: "normal"
    },
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontSize: '.75rem'
                },
                head: {
                    color: "#FFFF",
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    backgroundColor: "#05a1cd",
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    padding: '0px !important',
                    marginRight: 20,
                    marginBottom: 8,
                    textTransform: 'unset !important',
                    borderRadius: 'unset !important',
                    width: 158.94,
                    height: 31.64
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: '0.85rem',
                    color: '#012B62',
                    transform: 'translate(0, 1.5px) scale(0.75)'
                },
            },
        },
        MuiInput:{
            styleOverrides: {
                root: {
                    borderBottom: '1px solid rgb(3 43 98)',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    borderBottom: '1px solid rgb(3 43 98)',
                    fontSize: '0.85rem',
                    color: '#012B62',
                    marginTop: 0,
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    borderBottom: '1px solid rgb(3 43 98)',
                    // paddingBottom: 2,
                    marginTop: 0,
                    // paddingTop: 2,
                    fontSize: '0.85rem',
                    color: '#012B62'
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    paddingBottom: 2,
                    paddingTop: 2,
                    fontSize: '0.85rem',
                    color: '#012B62'
                },
            },
        },
    },
    palette: {
        primary: {
            main: '#012B62'
        },
        secondary: {
            main: '#05A1CD'
        },
        success: {
            main: '#4CAF50'
        },
        danger: {
            main: '#4CAF50'
        }
    }
}, ruRU);

render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <Router history={history}>
                <Switch>
                    <Route exact path='/login' component={Auth}/>
                    <Route exact path='/reset' component={Reset}/>
                    <Route path='/' component={App}/>
                </Switch>
            </Router>
            <Notifier />
            <Confirm />
        </Provider>
    </ThemeProvider>,
    document.getElementById('root')
);

serviceWorker.unregister();