import React, { Fragment } from 'react';
import moment from 'moment';
import {
    AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip,
    Legend, ResponsiveContainer
} from 'recharts';
import _ from 'lodash';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import  Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import { Spiner } from '../../_Components';

const toDate = (val) => `${moment(val).format("DD.MM")}`;

const sumDataArray = (array,key) => (_.compact(_.map(array,key)).reduce((x,y)=>{return x + y;}, 0));

const secondsToTime = (seconds) => { //перевод секунд в дату
    const date = new Date(null);
    let hourss = 0;
    let sec = seconds;
    if(seconds > 3600) {hourss = Math.floor(seconds/3600); sec = seconds - (3600 * hourss); };
    date.setSeconds(sec); // specify value for
    if (seconds > 3600){
        return hourss + date.toISOString().slice(13, 19);
    } else
        return date.toISOString().slice(11, 19);
}

const Calls = (props) => {
    const { calls, classes, minHeight, settings, isLoading } = props;
    return (
        <Grid container item xs={12} sm={settings.sl ? 6 : 12} alignItems="stretch" direction="row" className={classes.canvasItems} >
            <Grid item xs={12}>
                <div className={classes.blockLabel}>Звонки</div>
            </Grid>
            {!isLoading ?
                <Fragment>
                    <Grid item xs={12} sm={3}>
                        {!!settings.abandoned&&
                            <Card className={settings.abandoned ?  classes.card4 : classes.card2}>
                                <CardContent style={{padding:10}}>
                                    <div className={classes.callsLabel}>
                                        Звонков
                                    </div>
                                    <div className={classes.valuesCalls} style={{color:'#28589C'}}>
                                        <Typography variant="h6" component="div">
                                            {sumDataArray(_.map(calls,'inbound'),'total')} 
                                            {/* ({sumDataArray(calls,'total')}/{sumDataArray(_.map(calls,'outbound'),'total')}) */}
                                        </Typography>
                                    </div>
                                </CardContent>
                            </Card>
                        }
                        <Card className={settings.abandoned ? classes.card4 : classes.card2}>
                            <CardContent style={{padding:10}}>
                                <div className={classes.callsLabel}>
                                    Принятых
                                </div>
                                <div className={classes.valuesCalls}  style={{color:'#22aa99'}}>
                                    <Typography variant="h6" component="div">
                                        { sumDataArray(calls,'answered')}
                                    </Typography>
                                </div>
                            </CardContent>
                        </Card>
                        {!!settings.abandoned&&
                            <Card className={settings.abandoned ? classes.card4 : classes.card2}>
                                <CardContent style={{padding:10}}>
                                    <div className={classes.callsLabel}>
                                        Потерянных
                                    </div>
                                    <div className={classes.valuesCalls}  style={{color:'#b82e2e'}}>
                                        <Typography variant="h6" component="div">
                                            {sumDataArray(calls,'abandoned')}
                                        </Typography>
                                    </div>
                                </CardContent>
                            </Card>
                        }
                        <Card className={settings.abandoned ? classes.card4 : classes.card2}>
                            <CardContent style={{padding:10}}>
                                <div className={classes.callsLabel}>
                                    Время разговра
                                </div>
                                <div className={classes.valuesCalls}>
                                    <Typography variant="h6" component="div">
                                        { secondsToTime(sumDataArray(calls,'talkTime'))}
                                    </Typography>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={9} className={classes.chartBlock}>
                        <Grid container className={classes.chartGrid}>
                            <ResponsiveContainer
                                width="100%"
                                height="100%"
                                minHeight={minHeight-56}

                            >
                                <AreaChart data={calls}
                                            margin={{top: 10, right: 30, left: 0, bottom: 0}}
                                            style={{fontSize: "12px", color: "#0b2b61"}}>
                                    {!!settings.abandoned ?
                                        <defs>
                                            <linearGradient id="colorTotal" x1="0" y1="0" x2="0" y2="1">
                                                <stop offset="5%" stopColor="#28589C" stopOpacity={0.8}/>
                                                <stop offset="95%" stopColor="#28589C" stopOpacity={0}/>
                                            </linearGradient>
                                            <linearGradient id="colorAbandoned" x1="0" y1="0" x2="0" y2="1">
                                                <stop offset="5%" stopColor="#b82e2e" stopOpacity={0.8}/>
                                                <stop offset="95%" stopColor="#b82e2e" stopOpacity={0}/>
                                            </linearGradient>
                                            <linearGradient id="colorAnswered" x1="0" y1="0" x2="0" y2="1">
                                                <stop offset="5%" stopColor="#22aa99" stopOpacity={0.8}/>
                                                <stop offset="95%" stopColor="#22aa99" stopOpacity={0}/>
                                            </linearGradient>
                                        </defs>
                                        : 
                                        <defs>
                                            <linearGradient id="colorAnswered" x1="0" y1="0" x2="0" y2="1">
                                                <stop offset="5%" stopColor="#22aa99" stopOpacity={0.8}/>
                                                <stop offset="95%" stopColor="#22aa99" stopOpacity={0}/>
                                            </linearGradient>
                                        </defs>
                                    }
                                    <XAxis dataKey="id" tickFormatter={toDate}/>
                                    <YAxis/>
                                    <Legend/>
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <Tooltip labelFormatter={(label) => moment(label).format("DD.MM.YYYY")}/>
                                    {!!settings.abandoned&&
                                        <Area name="Всего" type="bump" dataKey="total" stroke="#28589C" 
                                            fillOpacity={1} fill="url(#colorTotal)"/>
                                    }
                                    <Area name="Принятых" type="bump" dataKey="answered" stroke="#22aa99"
                                            fillOpacity={1} fill="url(#colorAnswered)" />
                                    {!!settings.abandoned&&
                                        <Area name="Потерянных" type="bump" dataKey="abandoned" stroke="#b82e2e"
                                                fillOpacity={1} fill="url(#colorAbandoned)"/>
                                    }
                                </AreaChart>
                            </ResponsiveContainer>
                        </Grid>
                    </Grid>
                </Fragment>            
                :
                <Spiner />
            }
        </Grid>
    );
}


export default Calls;