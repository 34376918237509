import { fetchConstants } from '../_constants';

let requests = 0;
let creat = 0;

export function fetch(state = { loading: requests}, action) {
    switch (action.type) {
        case fetchConstants.START:
            requests++
            return {
                loading : requests,
                creat:  creat
            };
        case fetchConstants.CREATE:
            creat++
            return {
                loading : requests,
                creat:  creat
            };
        case fetchConstants.CREATE_END:
            creat--;
            return {
                loading : requests,
                creat:  creat
            };
        case fetchConstants.ERROR:
            requests--;
            return {
                loading : requests,
                creat:  creat
            };
        case fetchConstants.SUCCESS:
            requests--;
            return {
                loading : requests,
                creat:  creat
            };
        default:
            return state
    }
}