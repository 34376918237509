import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel} from '@mui/material';


import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

import { Card, Typography } from '@material-ui/core';

import ListToolbar from './ListToolbar';
import Spiner from '../Spiner';
import { 
  GET_LIST, 
  provider, 
  history, 
} from '../../_utils';

const styles = theme =>({
  root: {
    width: '100%',
    overflow: 'auto'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  table:{
    minWidth: 750,
    width: `calc(100% - ${theme.spacing(2)*2}px)`,
    marginLeft: theme.spacing(2),
  },
  skeleton:{
    backgroundColor: '#bbd9ea',
    height: 35,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  skeletonRow:{
    display: 'flex',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  sortIcon: {
    fontSize: '1rem'
  },
  headerSize: {
    padding: theme.spacing(1),
    fontSize: '0.75rem'
  },
  notFound:{
    textAlign: 'center',
    color: '#011e44',
    fontWeight: 600,
  },
  tableCell: {
    fontSize: '0.73rem'
  },
  sizeSmall: {
    paddingTop: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
  },
  header: {
    color: '#ffff'
  },
  row: {
    '&:hover': {
      backgroundColor: 'aliceblue'
    }
  }
});

const EnhancedTableHead = (props) => {
    const { classes, order, orderBy, onRequestSort, headCells = [], show, play } = props;
    const createSortHandler = property => event => {
      onRequestSort(event, property);
    };
    return (
      <TableHead>
        <TableRow>
          {headCells.map(headCell => (
            <TableCell
              classes={{
                root: classes.header
              }}
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding='normal'
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.notSort 
                ? 
                  <span><b>{headCell.label}</b></span>
                :
                  <TableSortLabel
                    classes = {{
                      icon: classes.sortIcon
                    }}
                    active={orderBy === headCell.id}
                    direction={order}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                }
              </TableCell>
          ))}
          {!!show&&
            <TableCell padding='normal'>
            </TableCell>
          }
          {!!play&&
            <TableCell padding='normal'>
            </TableCell>
          }
        </TableRow>
      </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    headCells: PropTypes.array.isRequired,
};

class EnhancedList extends Component{
    _isMounted = false;

    constructor(props) {
        super(props);
        const { children = [] } = props;
        let headers = [];
        children.map( el => {
          const label = el.props.label || el.props.source;
          const id = el.props.source;
          return headers.push({ id, label, ...el.props })
         
        })
        this.state = {
            order: props.sort.order.toLowerCase(),
            orderBy: props.sort.field,
            page: 0,
            total: 0,
            filterValues: props.filterDefaultValues || {},
            rowsPerPage: props.perPage,
            headers,
            loading: false,
            menuItem: null
        };
        this.fetchData = this.fetchData.bind(this);
        this.setCreate = this.setCreate.bind(this);
        this.setFilters = this.setFilters.bind(this);
        this.setPlay = this.setPlay.bind(this);

        this.handleRequestSort = this.handleRequestSort.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.handleMenu = this.handleMenu.bind(this);
        this.handleMenuClose = this.handleMenuClose.bind(this);
    }

    componentDidMount (){
      this._isMounted = true;
      this.fetchData();
    }

    componentWillUnmount() {
      this._isMounted = false;
    }

    async fetchData() {
      const { history, basePath } = this.props;
      const pathname = history.location.pathname.split('/');
      const { orderBy, order, page, filterValues, rowsPerPage } = this.state;
      let path = pathname[1];
      if(basePath){
        path = basePath;
      }
      this.setState({loading: true})
      const { data, total } = await provider(
          GET_LIST,
          `/${path}`,
          {
              filter: { ...filterValues },
              sort: { field: orderBy, order },
              pagination: { page: page+1, perPage: rowsPerPage },
          }
      );
      if(this._isMounted) {
        this.setState({loading: false})
        this.setState({ data, total, page: 0 });
      }
    }
    
    setCreate (type) {
      const { history, match } = this.props;
      let path = `${history.location.pathname}/create/?type=${type}`;
      if(!!(match&&match.params&&match.params.id)){
        const value = match.params.id;
        path =  history.location.pathname.replace(value, 'create');
      }
      history.push(path);
    }

    setShow (params) {
      const { id } = params;
      const { history, match, pathToShow, search } = this.props;
      let path = `${history.location.pathname}/${id}}${!!pathToShow ? pathToShow : ''}`;
      const searchArray = [];
      search.map( param => params[param]&&searchArray.push(`${param}=${params[param]}`));
      let searchStr = '?'
      if(searchStr.length>0){
        searchStr+=searchArray.join('&');
        path+=searchStr;
      }
      if(!!(match&&match.params&&match.params.id)){
        const value = match.params.id;
        path =  history.location.pathname.replace(value, id);
      }
      history.push(path);
    }

    setPlay(obj){
      const { dispatch } = this.props;
      dispatch({type: 'SHOW_PLAYER', payload: { record: obj } });
    }

    setFilters (values) {
      if(this._isMounted) {
        this.setState({filterValues: values});
      }
    }

    handleRequestSort (event, property) {
        const { orderBy, order } = this.state;
        const isDesc = orderBy === property && order === 'desc';
        this.setState({ order: isDesc ? 'asc' : 'desc', orderBy: property }, () => this.fetchData());
    };

    // handleChangePage (event, newPage) {
    //     this.setState({ page: newPage }, () => this.fetchData());
    // };

    // handleChangeRowsPerPage (event) {
    //     this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) }, () => this.fetchData())
    // };


  handleChangePage (event, newPage) {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage (event) {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 25) })
  };

    handleMenu (event) {
      this.setState({ menuItem: event.currentTarget});
    };
  
    handleMenuClose () {
      this.setState({ menuItem: null});
    };

    render(){
        const { 
          classes, 
          children, 
          filters, 
          match, 
          filter, 
          isLoading, 
          creat, 
          creatOption,
          customCreate,
          refresh, 
          show,
          play,
          player,
          actions
        } = this.props;
        const { 
          loading,
          order,
          orderBy,
          page,
          rowsPerPage,
          total,
          filterValues,
          data = [],
          headers = [],
          menuItem
        } = this.state;

        const controllerProps = {
          setFilters: this.setFilters,
          fetchData: this.fetchData,
          setCreate: this.setCreate
        };

        return(
          <Card className={classes.root}>
              {/* <EnhancedTableToolbar {...props}/> */}
              {filters && (
                  <ListToolbar
                      filters={filters}
                      isLoading={isLoading}
                      actions={actions}
                      {...controllerProps}
                      filterValues={filterValues}
                      permanentFilter={filter}
                      creat={creat}
                      customCreate={customCreate}
                      menuItem={menuItem}
                      handleMenu={this.handleMenu}
                      handleMenuClose={this.handleMenuClose}
                      creatOption={creatOption}
                      refresh={refresh}
                  />
              )}
              <div className={classes.tableWrapper}>
                {!loading?
                  <Fragment>
                    {(data.length===0&&!loading) ? 
                        <Typography className={classes.notFound}>
                          Результатов не найдено
                        </Typography>
                      :
                        <Fragment>
                          <Table
                              className={classes.table}
                              aria-labelledby="tableTitle"
                              size='small'
                              aria-label="enhanced table"
                          >
                              <EnhancedTableHead
                                  classes={classes}
                                  order={order}
                                  orderBy={orderBy}
                                  onRequestSort={this.handleRequestSort}
                                  headCells={headers}
                                  rowCount={data.length}
                                  show={show}
                                  play={play}
                              />
                              {data.length>0&&
                                <TableBody>
                                    {/* {data.map((row, index) =>{ */}
                                    {(rowsPerPage > 0
                                        ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : data
                                      ).map((row, index) => {
                                      const id = !!(match &&match.params &&match.params.id !== 'create') ? match.params.id : false;
                                      const selected = id === row.id || player.id === row.id;
                                      return(
                                        <TableRow 
                                          key={index}
                                          classes={{
                                            root: classes.row
                                          }}
                                          style={{ backgroundColor: selected&&"#05a1cd42" }}
                                        >
                                          {children.map( (child, i) => {
                                            return (
                                              <TableCell
                                                classes={{
                                                  root: classes.tableCell,
                                                  sizeSmall: classes.sizeSmall
                                                }}
                                                key={`${index}-${i}`}
                                              >
                                                {React.cloneElement(child, { value: row[child.props.source], row: child.props.row ? row : null })}
                                              </TableCell>)
                                          })}
                                          {!!show&&
                                              <TableCell
                                                classes={{
                                                  root: classes.tableCell
                                                }}
                                              >
                                                <IconButton onClick={() => this.setShow(row)} size='small' aria-label="Показать детали">
                                                  <KeyboardArrowRightIcon size="small"/>
                                                </IconButton>
                                              </TableCell>
                                          }
                                          {!!play&&
                                              <TableCell>
                                                <IconButton style={{color: '#0a529f'}} onClick={() => this.setPlay(row)} size='small' aria-label="Проиграть">
                                                  <PlayArrowIcon size="small"/>
                                                </IconButton>
                                              </TableCell>
                                          }
                                        </TableRow>
                                      )
                                    }
                                    )}
                                </TableBody>
                              }
                          </Table>
                          {data.length>0&&
                            <TablePagination
                                rowsPerPageOptions={[25, 50, 100]}
                                labelRowsPerPage="Строк на странице:"
                                component="div"
                                count={total}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                            />
                          }
                        </Fragment>
                    }
                  </Fragment>
                :
                    <Spiner />
                }
              </div>
          </Card>
        )
    }
}

const mapStateToProps = (state, props) =>{
    const { fetch, player } = state;
    return {
        history,
        player,
        isLoading: !!fetch.loading
    }
}

export default compose(
    connect(mapStateToProps),
    withStyles(styles),
)(EnhancedList);