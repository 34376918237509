import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFormValues, reduxForm, Field } from 'redux-form';
import compose from 'recompose/compose';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import logo from '../_img/logo.png';
// import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
// import Icon from '@material-ui/core/Icon';

import { userActions } from '../_utils';

const styles = theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        // background: `url(${Background})`,
        // backgroundRepeat: 'no-repeat',
        // backgroundSize: 'cover',
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        // gap: 74,
        position: 'absolute',
        top: 152,
        minWidth: 300,
        marginTop: '6em',
        boxShadow: 'none'
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        width: 396,
        // height: 199,
        flex: 'none',
        order: 1,
        flexGrow: 0
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'left',
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
    },
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        color: '#0a529f'
    },
    input: {
        marginTop: '1em',
    },
    actions: {
        padding: '30px 0px'
    },
    reset: {
        paddingTop: '1em'
    },
    button: {
        backgroundColor: '#012b62',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '13px 32px',
        gap: 10,
        width: 229,
        height: 40,
        border: '1px solid #012B62',
        boxShadow: '0px -1px 39px rgba(255, 255, 255, 0.25)',
        flex: 'none',
        order: 1,
        flexGrow: 0,
        borderRadius: 0,
        '&:hover': {
            backgroundColor: '#01a2cd',
        }
    },
    buttonLabel: {
        width: 'auto',
        textTransform: 'none'
    }

});

// see http://redux-form.com/6.4.3/examples/material-ui/
const renderInput = ({
    meta: { touched, error } = {},
    input: { ...inputProps },
    ...props
}) =>{
    return(
        <TextField
            error={!!(touched && error)}
            helperText={touched && error}
            {...inputProps}
            {...props}
            fullWidth
        />
    )
}

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            login: true,
            reset: false
        };
        this.submit = this.submit.bind(this);
        this.setResetForm = this.setResetForm.bind(this);
    }
    
    submit(values){
        this.props.login(values);
    }

    setResetForm(){
        const { history } = this.props;
        history.push("/reset")
    }

    render(){
        const { classes, handleSubmit, isLoading } = this.props;
        return (
            <div className={classes.main}>
                <Card className={classes.card}>
                    <div className={classes.avatar}>
                            <img src={logo} alt="Логотип"/>
                    </div>
                    <form onSubmit={handleSubmit(this.submit)} className={classes.form}>
                        <div >
                            <div className={classes.input}>
                                <Field
                                    autoFocus
                                    name="username"
                                    component={renderInput}
                                    label={`E-mail`}
                                    disabled={isLoading}
                                />
                            </div>
                            <div className={classes.input}>
                                <Field
                                    name="password"
                                    component={renderInput}
                                    label={`Пароль`}
                                    type="password"
                                    disabled={isLoading}
                                />
                            </div>
                        </div>
                        <CardActions classes={{
                            root: classes.actions
                        }}>
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                disabled={isLoading}
                                className={classes.button}
                                fullWidth
                                classes={{
                                    label: classes.buttonLabel
                                }}
                            >
                                {isLoading && (
                                    <CircularProgress size={25} thickness={2} />
                                )}
                                Войти 
                            </Button>
                            {/* <Link
                                component="button"
                                variant="body2" 
                                onClick={this.setResetForm}
                                className={classes.reset}
                            >
                                Забыли пароль?
                            </Link> */}
                        </CardActions>
                    </form>
                </Card>
            </div>
        );
    }
}

const actionCreators = {
    login: userActions.login,
    reset: userActions.reset,
};

const mapState = (state) => {
    const { fetch} = state;
    const isLoading = fetch.loading>0 || false;
    var recordLiveValues = getFormValues('auth')(state);
    return { formValues: recordLiveValues, isLoading }
}

const enhance = compose(
    connect( mapState, actionCreators),
    reduxForm({
        form: 'auth',
        validate: (values) => {
            const errors = {};
            if (!values.username) {
                errors.username = "Не верный e-mail";
            }
            if (!values.password) {
                errors.password = "Поле не может быть пустым";
            }
            return errors;
        },
    }),
    withStyles(styles)
);

const EnhancedLogin = enhance(Login);


export default EnhancedLogin;
