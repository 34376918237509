import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Switch, Route } from 'react-router-dom';
import { withRouter } from "react-router";
import {compose} from 'recompose';

import Dashboard from '../dashboard';
import Reports from '../reports';
import NotFound from './404';

const styles = theme =>({
  root: {
    position: 'absolute',
    top: `calc(100vh - ${theme.spacing(12)}px)`,
  },
  content: {
    flexGrow: 1,
    paddingLeft: theme.spacing(9),
    overflow: 'auto',
    padding: 0,
    position: 'absolute',
    height: `calc(100vh - ${theme.spacing(8)}px)`,
    width: `calc(100% - ${theme.spacing(9)}px)`,
  }
});

class Main extends Component{
    state = { 
        isExpanded: true,
        taskEl: null,
        block: ''
    }

    render(){
        const { classes } = this.props;
        
        return (
            <main className={classes.content} >
                {/* <Redirect from="/discount" to="/discount/acting" /> */}
                <Switch>
                    <Route exact path='/' component={Dashboard} />
                    <Route exact path='/report' component={Reports.list} />
                    <Route exact path='/report/:id' component={Reports.list} />
                    <Route path="*" component={NotFound} />
                </Switch>
                
            </main>
        );
    }
}

Main.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
    connect(),
    withRouter,
    withStyles(styles),
)(Main);