import React, { Component } from 'react';
import compose from 'recompose/compose';
import { Route } from 'react-router';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { createStyles, withStyles } from '@material-ui/core/styles';
import moment from 'moment';

import Drawer from '@material-ui/core/Drawer';

import { 
    List, 
    TextField, 
    DateField, 
    PhoneField, 
    TimeField, 
    SuccessField, 
    AddressField,
    Spiner
} from '../_Components';

import { reports } from '../_utils';

import Filter from './Filter';
import Show from './Show';
import ExportButton from './components/ExportButton';


const styles = theme =>
    createStyles({
        root: {
            display: 'flex',
            height: '100%'
        },
        list: {
            flexGrow: 1,
            transition: theme.transitions.create(['all'], {
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginRight: 0,
        },
        listWithDrawer: {
            marginRight: 500,
        },
        drawerPaper:{
            width: 500,
            marginTop: theme.spacing(7)
        }
});

class ReportList extends Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
        this.reports = reports(); 
        this.state = {
            headers: this.reports[0].headers,
            filter: {
                ...props.formValues || {},
                report: props.formValues ? props.formValues.report : this.reports[0]._id,
                interval: {
                    startDate: props.formValues ? props.formValues.interval.startDate : moment().startOf('day'),
                    endDate:  props.formValues ? props.formValues.interval.endDate : moment().endOf('day')
                }
            }
        };
    }

    componentDidMount(){
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    UNSAFE_componentWillMount() {
        this._isMounted = true;
    }

    render() {
        const { classes } = this.props;
        const { filter, headers } = this.state;
        const { reports } = this;
        if(!this._isMounted){
            return <Spiner />;   
        }
        return (
            <Route path="/report/:id">
                    {(props) => {
                        const { match } = props;
                        const isMatch = !!(
                            match &&
                            match.params 
                        );
                        return (
                            <div className={classes.root}>
                                <List
                                    // classes={{
                                    //     root: isMatch&&classes.listWithDrawer
                                    // }}
                                    refresh={true}
                                    creat={false}
                                    show={true}
                                    basePath='client/callsList'
                                    actions={<ExportButton reports={reports}/>}
                                    filters={<Filter reports={reports}/>}
                                    match={match}
                                    search={['callId']}
                                    filterDefaultValues={filter}
                                    perPage={25}
                                    deletedField='deleted'
                                    sort={{ field: 'time', order: 'ASC' }} 
                                >   
                                    {/* <PhoneField
                                        notSort={true}
                                        source="address"
                                        label="Телефон"
                                    /> */}
                                    {/* <DateField
                                        notSort={true}
                                        source="time"
                                        format="DD.MM.YYYY HH:mm:ss"
                                        label="Время"
                                    />
                                    
                                    
                                    <TextField
                                        notSort={true}
                                        source="waitTime"
                                        label="Ожидал"
                                    />
                                    <TextField
                                        notSort={true}
                                        source="totalTalkTime"
                                        label="Разговр"
                                    /> */}
                                    {headers.length>0&&
                                        headers.map( el => {
                                            switch (el.type) {
                                                case "datetime":
                                                    return <DateField
                                                        key={el.value}
                                                        notSort={true}
                                                        source={el.value}
                                                        row={true}
                                                        label={el.label}
                                                        format="DD.MM.YYYY HH:mm:ss"
                                                    />
                                                case "address":
                                                    return <AddressField
                                                        key={el.value}
                                                        notSort={true}
                                                        row={true}
                                                        source={el.value}
                                                        label={el.label}
                                                    />
                                                case "phone":
                                                    return <PhoneField
                                                        key={el.value}
                                                        notSort={true}
                                                        row={true}
                                                        source={el.value}
                                                        label={el.label}
                                                    />
                                                case "time":
                                                    return <TimeField
                                                        key={el.value}
                                                        notSort={true}
                                                        row={true}
                                                        source={el.value}
                                                        label={el.label}
                                                    />
                                                case "success":
                                                    return <SuccessField
                                                        key={el.value}
                                                        notSort={true}
                                                        row={true}
                                                        source={el.value}
                                                        label={el.label}
                                                    />
                                                default:
                                                    return <TextField
                                                        key={el.value}
                                                        notSort={true}
                                                        row={true}
                                                        source={el.value}
                                                        label={el.label}
                                                    />
                                            }
                                        })
                                    }
                                </List>
                                <Drawer
                                    // variant="persistent"
                                    open={isMatch}
                                    anchor="right"
                                    hideBackdrop={true}
                                    onClose={this.handleClose}
                                    classes={{
                                        paper: classes.drawerPaper,
                                    }}
                                >
                                    {isMatch ? (
                                        <div style={{padding: '0px 8px'}}>
                                            <Show id={match.params.id} />
                                        </div>
                                    ) : null}
                                </Drawer>
                            </div>
                        );
                    }}
                </Route>
            
        );
    }
}

const mapStateToProps = (state) => {
    var recordLiveValues = getFormValues('filterForm')(state);
    return { 
        formValues: recordLiveValues,
    };
};


export default compose(
    connect(mapStateToProps),
    withStyles(styles),
)(ReportList);
