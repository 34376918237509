import React from 'react';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import CloseIcon from '@material-ui/icons/Close';

let openConfirmFn;

class Confirm extends React.Component {
  state = {
    open: false,
    message: '',
    title: 'Закрыть?',
    confirmLabel: 'Закрыть'
  };

  componentDidMount() {
    openConfirmFn = this.openConfirm;
  }

  openConfirm = ({title, message , ...props}) => {
    this.setState({ open: true, title, message, ...props });
  };

  onClose = () => {
    this.setState({ open: false });
  }

  onConfirm = () =>{
    this.setState({ open: false });
    this.state.onConfirn();
  }

  render() {
    const { open, title, message, confirmLabel  } = this.state;
    return (
      <Dialog
        open={open}
        TransitionComponent={Slide}
        keepMounted
        onClose={this.handleClose}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{color: '#0a529f'}}>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            variant="contained"
            size="small"
            color="secondary"
            onClick={() => this.onConfirm()} 
          >
            { confirmLabel }
          </Button>
          <Button 
            size="small" 
            variant="outlined"
            onClick={ () => this.onClose()} 
            color="primary"
            startIcon={<CloseIcon />}
          >
            Отмена
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default Confirm;

export function openConfirm(obj, onConfirm, onClose) {
  return openConfirmFn(obj, onConfirm, onClose);
}