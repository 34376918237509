import React from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@mui/material';
import RefreshIcon from '@material-ui/icons/Refresh';

import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
    toolbar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
        gap: 22,
        height: 97,
        backgroundColor: theme.palette.background.default,
        border: '0.5px solid #BDBDBD',
        marginBottom: theme.spacing(1)
    },
    actions: {
        // marginLeft: 'auto',
    },
    actionsButton: {
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(1)
    }
});

const ListToolbar = ({
    classes,
    filters,
    filterValues, // dynamically set via the UI by the user
    permanentFilter, // set in the List component by the developer
    actions,
    bulkActions,
    exporter,
    isLoading,
    fetchData,
    setCreate,
    creat,
    creatOption,
    refresh,
    menuItem,
    handleMenu,
    handleMenuClose,
    changeMenu,
    ...rest
}) => (
    <Toolbar className={classes.toolbar}>
        {filters &&
            React.cloneElement(filters, {
                ...rest,
                filterValues,
                context: 'form',
            })
        }
        <div className={classes.actions}>
            {refresh&&
                <Button 
                    className={classes.actionsButton}
                    color="primary" 
                    size="small"
                    variant="contained"
                    endIcon={isLoading 
                        ? <CircularProgress size={20} thickness={2} />
                        : <RefreshIcon />
                    }
                    onClick={() => fetchData()}
                    disabled={isLoading}
                > 
                    Обновить
                </Button>
            }
            {actions &&
                React.cloneElement(actions, {
                    ...rest,
                    className: classes.actionsButton,
                    bulkActions,
                    exporter,
                    filters,
                    filterValues,
                    permanentFilter,
                    ...actions.props,
            })}
        </div>
    </Toolbar>
);

ListToolbar.propTypes = {
    classes: PropTypes.object,
    filters: PropTypes.element,
    permanentFilter: PropTypes.object,
    actions: PropTypes.element,
    bulkActions: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
    exporter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

ListToolbar.defaultProps = {
    classes: {},
};

export default withStyles(styles)(ListToolbar);
