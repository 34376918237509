import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import CallMadeIcon from '@material-ui/icons/CallMade';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import CallMissedIcon from '@material-ui/icons/CallMissed';

const styles = {
    main: {
        width: '100%',
        margin: 0,
        border: 0,
        display: 'inline-flex',
        padding: 0,
        position: 'relative',
        minWidth: 0,
        flexDirection: 'column',
        whiteSpace: 'nowrap'
    },
    div: {
        fontWeight: 700,
        // textAlign: 'center',
        color: 'currentColor',
        width: '100%',
        border: 0,
        margin: 0,
        display: 'block',
        boxSizing: 'content-box',
        background: 'none',
        verticalAlign: 'middle'
    },
}

const AddressField = ({classes, style, ...props}) =>{
    const tel = normolizePhone(props.value)
    const row = props.row;
    const phone = parsePhoneNumberFromString(`+${tel}`);
    let icon = ""
    if(row){
        if(row.Type==='Inbound'){
            if(row.Connected){
                icon =  <span className={classes.firstLine} style={{height: 22, paddingTop: 2}}>
                            <CallReceivedIcon style={{color: "#03A9F4", fontSize: 20, paddingRight: 4, marginBottom: -4}}/>
                        </span>
            }else{
                icon =  <span className={classes.firstLine} style={{height: 22, paddingTop: 2}}>
                            <CallMissedIcon style={{color: "#F44336", fontSize: 20, paddingRight: 4, marginBottom: -4}}/>
                        </span>
            }
        }else if(row.Type==='Outbound'){
            icon =  <span className={classes.firstLine} style={{height: 22, paddingTop: 2}}>
                        <CallMadeIcon style={{color: "#FF9800", fontSize: 20, paddingRight: 4, marginBottom: -4}}/>
                    </span>
        }
    }
    return (
        <div className={classes.main} style={style}>
            <div className={classes.div} style={{ color: row&&!row.Connected ? 'red' : '' }}>
                {icon}
                {phone? phone.formatInternational() :  '-'}
            </div>
        </div>
    )
}

const  normolizePhone = (tel) => {
    if (!tel) { return ''; }
    tel=tel.toString().trim().replace(/[^0-9]*/g, '');
    if(tel.length>=13){
      tel=tel.slice(tel.length-12,tel.length);
    }else if(tel.length<7){
      return tel
    }
    var value = tel.toString().trim().replace(/[^0-9]*/g, '');
    var country, city, number;
    switch (value.length) {
        case 7: // +1PPP####### -> C (PPP) ###-####
            country = '375';
            city = '17';
            number = value;
            break;
        case 9: // +CPPP####### -> CCC (PP) ###-####
            country = '375';
            city = value.slice(0, 2);
            number = value.slice(2);
            break;
        case 10: // +CPPP####### -> CCC (PP) ###-####
            country = '375';
            city = value.slice(1, 2);
            number = value.slice(2);
            break;
        case 11: //
            if(value.slice(0, 2)==='80'){
              country = '375';
              city = value.slice(2, 4);
              number = value.slice(4);
            }else{
              return tel;
            }
            break;
        case 12: // +CCCPP####### -> CCC (PP) ###-####
            country = '375';
            city = value.slice(3, 5);
            number = value.slice(5);
            break;

        default:
            return tel;
    }
    //number = number.slice(0, 3) + '-' + number.slice(3);

    return (country+city+number).trim();
}

export default withStyles(styles)(AddressField);
