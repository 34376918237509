import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { Select, MenuItem, LinearProgress, InputLabel, FormControl } from '@mui/material';

const styles = theme => ({
  text: {
    fontSize: '.85rem',
    color: '#0b2b61'
  }
});

class SelectInput extends Component{
  render(){
    const { input, classes, meta: { touched, error } = {}, label, ...props }  = this.props;
    return(
      <Fragment>
        {props.options 
        ?
        <FormControl>
            <InputLabel>{label}</InputLabel>
            <Select
              {...input}
              {...props}
              label={<InputLabel variant="standard" htmlFor="uncontrolled-native">
                        {label}
                      </InputLabel>
                    }
              // autoWidth={true}
              error={!!(touched && error)}
              // helperText={touched && error}
              variant="standard"
              // select
            >
                {props.options.map((item, key)=>(
                    <MenuItem disabled={item.disabled} key={key} value={item.id || item._id}>{item.name}</MenuItem>
                ))}
            </Select>
          </FormControl>
        :
          <LinearProgress {...props} style={{ marginTop: 24 }}/>
        }
      </Fragment>
    )
  }
}

export default  compose(
  withStyles(styles),
)(SelectInput);