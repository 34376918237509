import jwt_decode from 'jwt-decode'; 
export function permissions() {
    let token = localStorage.getItem('token');
    if (token) {
        return jwt_decode(token).role;
    } else {
        return {};
    }
}

export function reports() {
    let reports = localStorage.getItem('reports');
    if (reports) {
        return JSON.parse(reports);
    } else {
        return {};
    }
}