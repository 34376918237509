import React, { Fragment } from 'react';
import Grid from '@mui/material/Grid';
import  Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import  _ from 'lodash';
import { Spiner } from '../../_Components';

const toPercent = (val, count) => count>0 ? `${((val/count)*100).toFixed(2)}%` : '0%';
const resultsColor = [
    "#22aa99",
    "#316395",
    "#0099c6",
    "#9c5935",
    "#651067",
    "#ff9900", //!
    "#e67300",
    "#b77322",
    "#dc3912",

]

const Results = (props) => {
    const { results, classes, isLoading } = props;
    let count = 0;
    var leng = 2;
    if (results) {
        leng = (results).length+2;
        const sortData = _.sortBy(results, 'count').reverse();
        _.map(sortData,(elem)=>(count += elem.count));
        var renderData = _.map(sortData,(elem,i)=>{
                return (
                    <Fragment key={i}>
                        {elem.count>0&&
                            <Grid item key={elem.value} xs={12} className={classes.resultGrid}>
                                <Card className={classes.resultCard} style={{ borderLeft: `5px solid ${resultsColor[i]}`}}>
                                    <CardContent className={classes.resultContent}>
                                        <div className={classes.resultCardLabel}>
                                            {elem.label}
                                        </div>
                                        <div className={classes.resultBlock}>
                                            <div className={classes.resultCount}>
                                                <Typography component='div' variant='h5' color="secondary" gutterBottom >
                                                    {elem.count}  
                                                </Typography>
                                            </div>
                                            <div className={classes.resultPercent}>
                                                <Typography component='div' variant='h6' style={{color: resultsColor[i]}} gutterBottom>
                                                    {toPercent(elem.count, count)}
                                                </Typography>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                        }
                    </Fragment>
                );
            }
        )
    }
    return (
        <Grid container  spacing={leng}  justifyContent="center" alignItems="stretch" direction="row" className={classes.resultItem} >
            <Grid item xs={12}>
                <div className={classes.resultLabel}>Результаты обработки</div>
            </Grid>
                {!isLoading ?
                    <Fragment>
                        {renderData}
                    </Fragment>
                    :
                    <Spiner />
                }
        </Grid>
    );
}


export default Results;