import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { DateRange } from 'react-date-range';
import moment from 'moment';
import Popover from '@mui/material/Popover';
import * as locales from 'react-date-range/dist/locale';
import 'react-date-range/dist/styles.css'; // main style file
import './dateRange.css'; // theme css file
import DatePickerIcon from '../../_img/datepicker.svg';
import { InputLabel, FormControl } from '@mui/material';


const styles = theme => ({
    date: {
        // display: 'flex',
        // width: theme.spacing(15),
        paddingBottom: 5,
        // lineHeight: 19,
        // alignItems: 'center',
        // color: '#012B62'
    },
    label: {
        fontSize: 14,
        marginRight: 8,
    },
    calendar: {
        marginLeft: 8,
        paddingBottom: 5,
    },
    element: {
        borderBottom: '1px solid #012B62',
        marginRight: 24,
        display: 'flex'
    },
    component: {
        fontFamily: 'Montserrat',
        fontEeight: 400,
        fontSize: '.85rem',
        lineHeight: '1.4375em',
        boxSizing: 'border-box',
        cursor: 'text',
        display: 'inline-flex',
        position: 'relative',
        color: '#0b2b61'
    },
    flex: {
        display: 'flex',
        alignItems: 'flex-end',
        marginTop: 20,
        cursor: 'pointer'
    }
  });

const formatInterval = ({startDate, endDate}) => {
    return {
        startDate: startDate ? moment(startDate).startOf('day') : moment().startOf('day'), 
        endDate: endDate ? moment(endDate).endOf('day') : moment().endOf('day'), 
    }
}

const  DateRangeInput = (props) =>{
    const { input: { value, onChange }, maxDate, classes } = props;
    const {startDate, endDate} = value;

    const [ranges, setRanges] = React.useState({startDate: startDate ? new Date(startDate) : new Date(), endDate: endDate ? new Date(endDate) : new Date(), key: 'selection', color: '#012B62'});
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [datesPicked, setDatesPicked] = React.useState(0);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const selectRange= (range) => {
        setDatesPicked(datesPicked + 1);
        if (datesPicked % 2 !== 0) {
            setRanges(range);
            setAnchorEl(null);
            onChange(formatInterval(range));
        } else {
            setRanges(range);
        }
        
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <FormControl>
             <InputLabel>
                Интервал
            </InputLabel>
            <div className={classes.component}>
                <div onClick={handleClick} className={classes.flex}>
                    <div className={classes.element}>
                        <span className={classes.label}>С</span>
                        <span className={classes.date}>{moment(ranges.startDate).format("DD.MM.YYYY")}</span>
                        <img  className={classes.calendar} src={DatePickerIcon} alt="calendar"/>
                    </div>
                    <div className={classes.element}>
                        <span  className={classes.label}>По</span>
                        <span  className={classes.date}>{moment(ranges.endDate).format("DD.MM.YYYY")}</span>
                        <img  className={classes.calendar} src={DatePickerIcon} alt="calendar"/>
                    </div>
                </div>
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
            >
                <DateRange
                    locale={locales["ru"]}
                    ranges={[ranges]}
                    months={2}
                    maxDate={new Date(maxDate)}
                    direction="horizontal"
                    onChange={(item) => selectRange(item.selection)}
                    showSelectionPreview={true}
                    showMonthAndYearPickers={false}
                    showDateDisplay={false}
                    color="#032b62"
                />
            </Popover>
        </FormControl>
    )
}


export default  compose(
    withStyles(styles),
)(DateRangeInput);
  
  

