import React, { Component } from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withRouter } from "react-router";
import {compose} from 'recompose';
import Tooltip from '@material-ui/core/Tooltip';

import AppsIcon from '@material-ui/icons/Apps';
// import BarChartIcon from '@material-ui/icons/BarChart';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
// import VolumeUpIcon from '@material-ui/icons/VolumeUp';

import { colorConstants, userActions, permissions } from '../_utils';
import Responsive from './responsive';

const drawerWidth = 240;
const styles = theme =>{
    return ({
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            paddingTop: 48,
            whiteSpace: 'nowrap',
        },
        drawerOpen: {
            paddingTop: 48,
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            paddingTop: 48,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.spacing(9) + 1,
        },
        list:{
            paddingTop: 0,
            paddingBottom: 48
        },
        listIcon:{
            color: colorConstants.primary,
            justifyContent: 'center'
        },
        listItem: {
            flexDirection: 'column',
            color: colorConstants.primary,
            "&.Mui-selected": {
                backgroundColor: colorConstants.primary,
                '& div':{
                    color: colorConstants.white,
                    '& p':{
                        color: colorConstants.white
                    }
                },
                "&:hover": {
                    backgroundColor: "#0b2b60d4",
                    '& div':{
                        color: colorConstants.white,
                        '& p':{
                            color: colorConstants.white
                        }
                    },
                }
            },
            "&:hover": {
                backgroundColor: "#0b2b60d4",
                '& div':{
                    color: colorConstants.white,
                    '& p':{
                        color: colorConstants.white
                    }
                },
            }
        },
        title: {
            fontSize: '0.61rem',
            fontWeight: '600',
            color: colorConstants.primary,
        }
    });
} 


export const MenuItems = [
    {
        to: '/',
        title: 'Главная',
        responsive: ['xsmall', 'small', 'medium'],
        icon: <AppsIcon />
    },
    {
        to: '/report',
        title: 'Отчеты',
        // permissions: ['admin', 'supervisor'],
        responsive: ['xsmall', 'small', 'medium'],
        icon: <AssignmentIcon />
    },
    // {
    //     to: '/calls',
    //     title: 'Журнал',
    //     // permissions: ['admin', 'supervisor'],
    //     responsive: ['xsmall', 'small', 'medium'],
    //     icon: <VolumeUpIcon />
    // },
    // {
    //     to: '/statistics',
    //     title: 'Статистика',
    //     permissions: ['admin', 'supervisor'],
    //     responsive: ['xsmall', 'small', 'medium'],
    //     icon: <BarChartIcon />
    // },
    {
        to: '/login',
        title: 'Выход',
        responsive: ['xsmall', 'small'],
        onClick: userActions.logout,
        icon: <ExitToAppIcon />
    },
];

class Menu extends Component{
    state = {
        open: false,
    }

    componentDidMount(){
        const pathname = this.props.location.pathname.split('/');
        const item = MenuItems.find( m => m.to === `/${pathname[1]}`)
        document.title = !!item ? item.title : 'Profcontact'
    }

    componentDidUpdate(prev){
        const pathname = this.props.location.pathname.split('/');
        
        const item = MenuItems.find( m => {
            const mainPath = m.to.split('/');
            return pathname[1] === mainPath[1] ? true : false
        })
        document.title = !!item ? `${item.title}`: 'Profcontact'
    }
    render(){
        const { classes, location } = this.props;
        const { open } = this.state;
        const permission = permissions();
        return (
            <Responsive
                xsmall={
                    <Drawer
                        variant="permanent"
                        className={clsx(classes.drawer, {
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        })}
                        classes={{
                            paper: clsx({
                                [classes.drawerOpen]: open,
                                [classes.drawerClose]: !open,
                            }),
                        }}
                        open={open}
                    >
                        <List>
                            {MenuItems.map((item, index) =>{
                                const pathname = location.pathname.split('/');
                                const mainPath = item.to.split('/');
                                return (
                                    <ListItem button key={index} component={Link} to={item.to} onClick={item.onClick} selected={pathname[1] === mainPath[1] ? true : false}>
                                        <Tooltip title={item.title} placement="right" arrow>
                                            <ListItemIcon>
                                                {item.icon} 
                                            </ListItemIcon>
                                        </Tooltip>
                                    </ListItem>
                                )
                            })}
                        </List>
                    </Drawer>
                }
                small={
                    <Drawer
                        variant="permanent"
                        className={clsx(classes.drawer, {
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        })}
                        classes={{
                            paper: clsx({
                                [classes.drawerOpen]: open,
                                [classes.drawerClose]: !open,
                            }),
                        }}
                        open={open}
                    >
                        <List
                            classes={{
                                root: classes.list
                            }}
                        >
                            {MenuItems.map((item, index) => (
                                <ListItem button key={index} component={Link} to={item.to} onClick={item.onClick} selected={item.to === location.pathname ? true : false}>
                                    {/* <HtmlTooltip
                                        arrow
                                        placement="right" 
                                        title={
                                        <React.Fragment>
                                            <Typography color="inherit">{item.title}</Typography>
                                        </React.Fragment>
                                        }
                                    > */}
                                        <ListItemIcon
                                            classes={{
                                                root: classes.listIcon
                                            }}
                                        >
                                            {item.icon} 
                                        </ListItemIcon>
                                        <ListItemText
                                            secondary={'Secondary text'}
                                        />
                                    {/* </HtmlTooltip> */}
                                    {/* <ListItemText primary={item.title} /> */}
                                </ListItem>
                            ))}
                        </List>
                    </Drawer>
                }
                medium={
                    <Drawer
                        variant="permanent"
                        className={clsx(classes.drawer, {
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        })}
                        classes={{
                            paper: clsx({
                                [classes.drawerOpen]: open,
                                [classes.drawerClose]: !open,
                            }),
                        }}
                        open={open}
                    >
                        <List
                            classes={{
                                root: classes.list
                            }}
                        >
                            {MenuItems.map((item, index) =>{
                                if(!item.permissions || (item.permissions&&item.permissions.indexOf(permission)>=0)){
                                    const pathname = location.pathname.split('/');
                                    const mainPath = item.to.split('/');
                                    return (
                                        <ListItem 
                                            button 
                                            key={index} 
                                            component={Link}
                                            to={item.to} 
                                            onClick={item.onClick} 
                                            selected={pathname[1] === mainPath[1] ? true : false}
                                            classes={{
                                                root: classes.listItem,
                                                selected: classes.selected
                                            }}
                                        >
                                            <ListItemIcon
                                                classes={{
                                                    root: classes.listIcon
                                                }}
                                            >
                                                {item.icon} 
                                            </ListItemIcon>
                                            <ListItemText
                                                classes={{
                                                    secondary: classes.title
                                                }}
                                                secondary={item.title}
                                            />
                                        </ListItem>
                                    )
                                }else{
                                    return null;
                                }
                            })}
                        </List>
                    </Drawer>
                }
            />
        );
    }
}

export default compose(
    withRouter,
    withStyles(styles),
)(Menu);    