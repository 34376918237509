import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = {
    main: {
        width: '100%',
        marginBottom: 8,
        margin: 0,
        border: 0,
        display: 'inline-flex',
        padding: 0,
        position: 'relative',
        minWidth: 0,
        flexDirection: 'column'
    },
    div: {
        color: 'currentColor',
        width: '100%',
        border: 0,
        margin: 0,
        padding: '8px 0 4px',
        display: 'block',
        boxSizing: 'content-box',
        background: 'none',
        verticalAlign: 'middle'
    },
    label: {
        position: 'relative',
        transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
        transform: 'translate(0, 1.5px) scale(0.75)',
        transformOrigin: 'top left',
        top: 0,
        left: 0,
        color: 'rgba(0, 0, 0, 0.54)',
        padding: 0,
        fontSize: '1rem',
        lineHeight: 1
    }
}

const Labeled = ({classes, style, children, ...props}) =>(
    <div className={classes.main} style={style}>
        <label className={classes.label}>
            {props.label}
        </label>
        <div className={classes.div}>
            {children}
        </div>
    </div>
)

export default withStyles(styles)(Labeled);
