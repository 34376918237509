import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import { AppBar, Menu, Main } from './_Components';

import { history, userService } from './_utils';
import { Spiner } from './_Components';

const App = (props) => {
    const { token, reports, userId } = props;
    const [ready, setReady] = useState(false);
    const [title] = useState('');

   
    useEffect( ()=>{
        const getUserData = async() => {
            const { reports } = await userService.get(userId);
            if(reports.length>0){
                setReady(true);
            }
        }
        if(!token){
            history.push('/login');
        }
        if(reports&&!ready){
            setReady(true);
        }else if(!reports&&!ready){
            getUserData();
        }
    },[token, reports, ready, userId])
    
    return (
        <Fragment>
            {ready ?
                <Fragment>
                    <AppBar title={title} />
                    <Menu />
                    <Main />
                </Fragment>
                :
                <Spiner/>
            }
        </Fragment>
    );

}

// class App extends Component {
//     state = {
//         done: false,
//         loading: false,
//         selectedMenu:  {path: 'dashboard'},
//         title: '',
//         url: window.location.href,
//     }

//     async getUserData() {
//         const { reports } = await userService.get(this.props.userId);
//         if(reports.length>0){
//             this.setState({done: true, loading: false}, () => this.getUserData())
//         }
//     }

//     componentDidMount(){
//         if(!this.props.reports&&!this.state.loading){
//             this.setState({done: false, loading: true}, () => this.getUserData())
//         }
//         if(this.props.reports&&!this.state.done){
//             this.setState({done: true});
//         }
//         if(!this.props.token){
//             history.push('/login')
//         }
//     }

//     componentDidUpdate(prevProps, prevState){
//         console.log(this.state);
//         if(!this.props.reports&&!this.state.loading){
//             this.setState({done: false, loading: true}, () => this.getUserData())
//         }
//         console.log(this.props.reports&&!this.state.done);
//         if(this.props.reports&&!this.state.done){
//             this.setState({done: true});
//         }
//         if(!this.props.token){
//             history.push('/login')
//         }

//     }

//     setTitle = (menu) => {
//         this.setState({title: menu ? menu.title : ''})
//     }

//     render(){
//         const { title, done } = this.state;
//         console.log(done);
//         return (
//             <Fragment>
//                 <AppBar title={title} />
//                 <Menu onSelect={this.onSelect} />
//                 {done&&
//                     <Main />
//                 }
//             </Fragment>
//         );
//     }
// }

const tokenToProps = () => {
    let token = localStorage.getItem('token');
    let reports = !!localStorage.getItem('reports') ? JSON.parse(localStorage.getItem('reports')) : null;
    let userId = localStorage.getItem('userId');
    let permission = localStorage.getItem('permission');
    return { token, permission, reports, userId };
}

export default compose(
    connect(null, tokenToProps),
)(App);