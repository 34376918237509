import React, { Fragment } from 'react';
import moment from 'moment';
import {
    AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip,
    Legend, ResponsiveContainer
} from 'recharts';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import  Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Spiner } from '../../_Components';

const toDate = (val) => `${moment(val).format("DD.MM")}`;
const toPercent = (val) => `${val}%`;

const Servicelevel = (props) => {
    const { calls, summary,  classes, minHeight, settings, isLoading } = props;
    return (
        <Grid container item xs={12} sm={6} alignItems="flex-start" direction="row" className={classes.canvasItems}>
            <Grid item xs={12}>
                <div className={classes.blockLabel}>Уровень обслуживания</div>
            </Grid>
            {!isLoading ?
                <Fragment>

                <Grid item xs={12} sm={3} >
                    <Card className={classes.card2}>
                        <CardContent className={classes.contentSL}>
                            <div className={classes.slLabel}>
                                Уровень сервиса
                            </div>
                            <div className={classes.valuesSL}>
                                <Typography component='div' variant='h5' gutterBottom>
                                   {summary.sl || 0}%
                                </Typography>
                            </div>
                        </CardContent>
                    </Card>
                    {settings.abandoned&&
                        <Card className={classes.card2}>
                            <CardContent className={classes.contentSL}>
                                <div className={classes.slLabel}>
                                    Потеряно
                                </div>
                                <div className={classes.valuesAR}>
                                    <Typography component='div' variant='h5' gutterBottom>
                                        {summary.abandonedWaitMoreThan5Percent || 0}%
                                    </Typography>
                                </div>
                            </CardContent>
                        </Card>
                    }
                </Grid>
                <Grid item xs={12} sm={9} className={classes.chartBlock}>
                    <Grid container className={classes.chartGrid}>
                        <ResponsiveContainer
                            width="100%"
                            height="100%"
                            minHeight={minHeight-56}
                        >
                            <AreaChart data={calls}
                                        // margin={{top: 10, right: 30, left: 0, bottom: 0}}
                                        style={{fontSize: "12px", color: "#0b2b61"}}
                            >
                                <defs>
                                    <linearGradient id="colorSl" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#109618" stopOpacity={0.8}/>
                                        <stop offset="95%" stopColor="#109618" stopOpacity={0}/>
                                    </linearGradient>
                                {settings.abandoned&&
                                    <linearGradient id="colorAbandonedPercent" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#dc3912" stopOpacity={0.8}/>
                                        <stop offset="95%" stopColor="#dc3912" stopOpacity={0}/>
                                    </linearGradient>
                                }
                                </defs>
                                <XAxis dataKey="id" tickFormatter={toDate}/>
                                <YAxis tickFormatter={toPercent}/>
                                <Legend/>
                                <CartesianGrid strokeDasharray="3 3"/>
                                <Tooltip labelFormatter={(label) => moment(label).format("DD.MM.YYYY")}/>
                                <Area name="Уровень обслуживания" type="bump" dataKey={(x) => {
                                    return (Math.round(x.sl * 100) / 100)
                                }} stroke="#109618" fillOpacity={1} fill="url(#colorSl)" formatter={(value, name, props) => `${value}%`}/>
                                {settings.abandoned&&
                                    <Area name="Потеряно" type="bump" dataKey={(x) => {
                                        return (Math.round(x.abandonedPercent * 100) / 100)
                                    }} stroke="#dc3912" fillOpacity={1} fill="url(#colorAbandonedPercent)" formatter={(value, name, props) => `${value}%`}/>
                                }
                            </AreaChart>
                        </ResponsiveContainer>
                    </Grid> 
                </Grid>
                </Fragment>

                :
                <Spiner />
            }
        </Grid>
    );
}


export default Servicelevel;