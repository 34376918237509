import React, { Component } from 'react';
import moment from 'moment';
import "moment/locale/ru";
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';

import InputAdornment from '@material-ui/core/InputAdornment';
import MomentUtils from '@date-io/moment';
// MomentUtils.prototype.getStartOfMonth=MomentUtils.prototype.startOfMonth
// import DateFnsUtils from '@date-io/date-fns';
import DatePickerIcon from '../../_img/datepicker.svg';
moment.locale("ru");

const styles = theme => ({
  root: {
      display: 'flex',
      width: theme.spacing(15),
      marginRight: 20,
      fontSize: 14,
      lineHeight: 19,
      alignItems: 'center',
      color: '#012B62'
  }
});

class DateInput extends Component{
  state ={
     date: moment()
  }

  render(){
    const { input, meta: { touched, error } = {}, classes, ...props }  = this.props;
    console.log(classes)
    return(
      <MuiPickersUtilsProvider utils={MomentUtils}>
        {/* <DatePicker value={input.value} onChange={input.onChange} /> */}
        <DatePicker 
          disableToolbar
          autoOk={true}
          variant="inline"
          format="DD.MM.YYYY"
          invalidDateMessage="Не верный формат даты"
          error={!!(touched && error)}
          helperText={touched && error}
          value={input.value}
          onChange={input.onChange}
          InputProps={{
              endAdornment: 
                  <InputAdornment position="end" style={ { color: '#05A1CD' } }>
                      <img src={DatePickerIcon} alt="" />
                  </InputAdornment>
          }}
          {...props}
        />
      </MuiPickersUtilsProvider>
    )
  }
}


export default  compose(
  withStyles(styles),
)(DateInput);

