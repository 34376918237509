import React, { Component, Fragment } from 'react';
import compose from 'recompose/compose';
import FileSaver from 'file-saver';
import {connect} from 'react-redux'
import { Field, reduxForm } from 'redux-form';

import {
    withStyles,
} from '@material-ui/core';

import { Dialog, DialogTitle, DialogContent, DialogActions, LinearProgress } from '@mui/material';
import { Button } from '@mui/material';

import CloseIcon from '@material-ui/icons/Close';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import { TextInput } from '../../_Components';
import { authHeader } from '../../_utils';

const styles = theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        display: 'flex',
        width: '40%'
    },
    tableHeader: {
        padding: 1,
        fontSize: 11,
    },
    table: {
        padding: 1,
        fontSize: 11,
        color: '#084c92',
    },
    close: {
        color: "#f44336",
        border: "1px solid #f4433663",
        '&:hover': {
            border: '1px solid #f44336',
            backgroundColor: '#ff000008',
        }
    },
    form: {
        display: 'flex',
        paddingBottom: theme.spacing(2),
    },
    name: {
        display: 'flex',
        width: theme.spacing(40),
        marginRight: 20
    },
    code: {
        display: 'flex',
        width: theme.spacing(10),
        marginRight: 20
    },
    addButton: {
        marginTop: theme.spacing(2),
        marginRight: 20,
        marginBottom: theme.spacing(2),
    }
});


class ExportModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            panels: [],
            applications: [],
            isSaving: false,
            tab: 0
        };
        this.save = this.save.bind(this);
    }

    async save() {
        this.setState({ isSaving: true })
        const { formValues, fileName, handleCloseClick } = this.props;
        const response = await fetch('/api/client/export', {
            credentials: 'same-origin',
            method: 'post',
            responseType: 'arraybuffer',
            headers: {...authHeader(), 'Content-Type': 'application/json'},
            body: JSON.stringify({fileName, ...formValues })
        })
        this.setState({ isSaving: false });
        response.arrayBuffer().then(function(buffer) {
            var blob = new Blob([buffer], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            FileSaver.saveAs(blob, `${fileName}.xlsx`);
            handleCloseClick();
        });
    }

    handleCloseClick = () => {
        this.props.handleCloseClick();
    }

    render() {
        const { isSaving } = this.state;
        const {classes, showDialog } = this.props;
        return (
            <Fragment>
                <Dialog
                    // maxWidth='lg'
                    classes={{ paper: classes.dialogPaper }}
                    fullWidth={true}
                    open={showDialog}
                    onClose={this.handleCloseClick}
                >
                    <DialogTitle>Экспорт отчета</DialogTitle>
                    <DialogContent>
                        <Field
                            // allowEmpty
                            name="fileName"
                            label={`Название файла`}
                            disabled={isSaving}
                            component={TextInput}
                        />
                        {isSaving&&<LinearProgress />}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="primary" 
                            size="small"
                            variant="contained"
                            onClick={this.save}
                            disabled={isSaving}
                            startIcon={<SaveAltIcon />}
                        >
                            Сохранить
                        </Button>
                        <Button
                            className={classes.close}
                            onClick={this.handleCloseClick}
                            startIcon={<CloseIcon />}
                        >
                            Закрыть
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
      initialValues: {
        fileName: props.fileName,
      }
    }
  }

export default compose(
    withStyles(styles),
    connect(mapStateToProps),
    reduxForm({
        form: 'exportForm',
        enableReinitialize: true,
        validate: (values) => {
            const errors = {};
            if (!values.fileName) {
                errors.fileName = 'Обазятельное поле'
            } 
            return errors;
        },
        destroyOnUnmount: true, 
    })
)(ExportModal);