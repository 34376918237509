import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import moment from 'moment';
import { reduxForm, Field } from 'redux-form';

import { withStyles } from '@material-ui/core/styles';

import { SelectInput, DateRangeInput } from '../_Components';

const styles = theme => ({
    form: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
        gap: 22,
        height: 97,
        backgroundColor: theme.palette.background.default,
        border: '0.5px solid #BDBDBD'
    },
    date: {
        display: 'flex',
        width: theme.spacing(15),
        marginRight: 20,
        fontSize: 14,
        lineHeight: 19,
        alignItems: 'center',
        color: '#012B62'
    },
    reports: {
        display: 'flex',
        width: theme.spacing(20),
        marginRight: 20
    },
    actionsButton: {
        marginBottom: 0,
        marginTop: 16
    }
    
});

class AnalysisFilter extends Component {

    render(){
        const { classes, reports } = this.props;
        return (
            <div className={classes.form}>
                    <Field
                        name="interval"
                        component={DateRangeInput}
                        maxDate={moment().endOf('day')}
                        className={classes.date}
                    />
                    {(reports&&reports.length>1)&&
                        <Field
                            name="report"
                            label={`Отчет`}
                            options={reports}
                            className={classes.reports}
                            component={SelectInput}
                        />
                    }
                    {/* <Button 
                        classes={{ root: classes.actionsButton}}
                        color="primary" 
                        size="small"
                        variant="contained"
                        endIcon={isLoading 
                            ? <CircularProgress size={20} thickness={2} />
                            : <RefreshIcon />
                        }
                        onClick={() => fetchData()}
                        disabled={isLoading}
                    > 
                        Обновить
                    </Button> */}
                <div className={classes.clearFix} />
            </div>
        );
    }
}


const mapStateToProps = (state, props) => {
    return {
      initialValues: {
        ...props.initialValues,
      }
    }
}

export default  compose(
    connect(mapStateToProps),
    withStyles(styles),
    reduxForm({
        form: 'dashboardForm',
        enableReinitialize: false,
        destroyOnUnmount: false, 
        onChange: (values, dispatch, props) =>{
            return props && props.setFilters(values);
        }
    })
)(AnalysisFilter);