import React, {Component} from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { 
  PhoneField, 
  TimeField,
  DateField
} from '../../_Components';

class Track extends Component {
  render() {
    let {track, play, currentTrack, download } = this.props;
    return <TableRow style={{ cursor: 'pointer', backgroundColor: currentTrack===track.id ? 'aliceblue' : ''}}>
            <TableCell  onClick={ ()=> play(track)}>
              <DateField value={track.start} format="DD.MM.YYYY HH:mm:ss"/>
            </TableCell>
            <TableCell  onClick={ ()=> play(track)}>
              <PhoneField value={track.originator} />
            </TableCell>
            <TableCell  onClick={ ()=> play(track)}>
              <TimeField
                  value={track.duration}
              />
            </TableCell>
            <TableCell>
              <ListItemIcon onClick={() => download(track)} style={{color: '#0b2b60'}}>
                  <GetAppIcon/>
                </ListItemIcon>
            </TableCell>
          </TableRow>;
  }
}

export default Track;
