import React, { Component } from 'react';
import { TextField } from '@mui/material';

class PhoneInput extends Component{
  
  formatPhone = (value) =>{
    return value.replace(/[^0-9]/g,"");
  }

  render(){
    const { input: { ...inputProps }, meta: { touched, error } = {}, ...props }  = this.props;
    return(
      <TextField
        // type="number"
        variant="standard"
        error={!!(touched && error)}
        helperText={touched && error}
        onChange={({ target: { value }}) => inputProps.onChange(this.formatPhone(value))}
        InputProps={{
          inputProps:{
            ...inputProps,
          }
        }}
        {...props}
        fullWidth
      />
    )
  }
}

export default PhoneInput;
