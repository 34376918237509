import React from 'react';
import { useTheme } from '@nivo/core'
import Grid from '@mui/material/Grid';
import { ResponsiveBar } from '@nivo/bar';

import { Spiner } from '../../_Components';

// const colors = [
//     "#22aa99",
//     "#0099c6",
//     "#aaaa11",
//     "#3366cc",
//     "#ff9900", //!
//     "#990099",
//     "#b82e2e",//!
//     "#316395",
//     "#994499",
//     "#651067",
//     "#329262",
//     "#5574a6",
//     "#b77322",
//     "#b91383",
//     "#9c5935",
//     "#2a778d",
//     "#0c5922",
//     "#743411"
// ];
const Tasks = (props) => {
        const { data: { data, keys}, classes, isLoading } = props;
        const Tooltip = ({id, value, color}) => {
            const splitArr = id.split(':');
            return (
                <div
                    style={{
                        backgroundColor: '#ffff',
                        padding: 16,
                        fontFamily: 'Montserrat',
                        fontSize: 12,
                        fontWeight: 600,
                        borderRadius: 4,
                        border: '1px solid #012B62',
                        color: '#012B62'
                    }}
                >
                    {id} {splitArr[splitArr.length-1] ? `(${value.toFixed(0)}%)` : `: ${value}`}
                </div>
            )
        }
        const Label = d => {
            const splitArr = d.id.split(':');
            return splitArr[splitArr.length-1] ? splitArr[splitArr.length-1] : d.value;
        }

        const CustomTick = (tick) => {
            const theme = useTheme();
            return(
                <g transform={`translate(${tick.x-16},${tick.y})`}>
                    <text
                        textAnchor="end"
                        style={{
                            ...theme.axis.ticks.text,
                            fill: '#012B62',
                            fontFamily: 'Montserrat',
                            fontSize: 12,
                        }}
                    >
                        {tick.value}
                    </text>
                </g>
            )
        }

        return (
            <Grid container  spacing={2} className={classes.taskItem} justifyContent="center" alignItems="stretch" direction="row">
                <Grid item xs={12}>
                    <div className={classes.resultLabel}>Темы обращений</div>
                </Grid>
                    {!isLoading ? 
                        <ResponsiveBar
                            style={{marginTop: -80}}
                            data={data}
                            keys={keys}
                            mydata = {data}
                            indexBy="theme"
                            margin={{ top: 0, right: 24, bottom: 24, left: 320 }}
                            padding={0.15}
                            layout="horizontal"
                            valueScale={{ type: 'linear' }}
                            indexScale={{ type: 'band', round: true }}
                            colors={{ scheme: 'nivo' }}
                            borderColor={{
                                from: 'color',
                                modifiers: [
                                    [
                                        'darker',
                                        '1.3'
                                    ]
                                ]
                            }}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: '% звонков',
                                legendPosition: 'middle',
                                legendOffset: 32
                            }}
                            axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: '', /*здесь можно легенду слуыв от оси сделать */
                                legendPosition: 'middle',
                                legendOffset: -140,
                                renderTick: CustomTick
                            }}
                            enableLabel={true}
                            label={Label}
                            labelSkipWidth={12}
                            labelSkipHeight={12}
                            labelTextColor="#0b2b61"
                            tooltip={Tooltip} //здесь выводится всплывающая подсказка
                            
                            role="application"
                            ariaLabel="Темы обращений"
                            barAriaLabel={ (e) => `${e.id}: + в: ${+e.indexValue}`}
                        />
                    :
                        <Spiner/>
                }
            </Grid>

    );
}


export default Tasks;