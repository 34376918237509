import { createStore, combineReducers, applyMiddleware } from 'redux';
import { reducer as formReducer } from 'redux-form'
import thunkMiddleware from 'redux-thunk';
// import { createLogger } from 'redux-logger';

import { authentication } from './authentication.reducer';
import { fetch } from './fetch.reducer';
import { alert } from './alert.reducer';
import { confirm } from './confirm.reducer';
import { filters } from './filters.reducer';
import { basket } from './basket.reducer';
import { player } from './player.reducer';





// const loggerMiddleware = createLogger();


const rootReducer = combineReducers({
    form: formReducer,
    authentication,
    fetch,
    alert,
    confirm,
    filters,
    basket,
    player
});

export const store =  createStore(rootReducer,
    applyMiddleware(
        thunkMiddleware,
        // loggerMiddleware
    )
);

