import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
// import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';

import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import { LinearProgress } from '@material-ui/core';
import { withRouter } from "react-router";
import {compose} from 'recompose';

import Responsive from './responsive';
import logo from '../_img/logo.png';

import { colorConstants, userActions } from '../_utils';

const styles = theme => ({
    root: {
        zIndex: theme.zIndex.drawer + 1,
        flexGrow: 1,
        width: '100%',
        position: 'absolute',
    },
    buttonRoot: {
        '&:hover': {
            backgroundColor: '#fbb725',
        }
    },
    toolbarRoot: {
        paddingLeft: 16,
        paddingRight: 16,
        minHeight: 48,
        backgroundColor: colorConstants.primary,

    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    img: {
        padding: 3,
        width: 95,
        filter: 'brightness(0.01) invert(1)'
    },
    title: {
        flexGrow: 1,
    },
});

class MenuAppBar extends Component{
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null
        };
        this.handleProfile = this.handleProfile.bind(this);
        this.handleMenu = this.handleMenu.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }

    handleMenu(event){
        this.setState({anchorEl: event.currentTarget});
    };

    handleProfile(event) {
        this.setState({anchorEl: null});
        this.props.history.push('/profile');
    };

    handleClose(){
        this.setState({anchorEl: null});
    }

    handleLogout(event) {
        this.setState({anchorEl: null});
        userActions.logout();
    };
    
    render(){
        const { classes, isLoading } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        return (
            <div className={classes.root}>
                <AppBar position="static">
                    <Toolbar
                        classes={{
                            root: classes.toolbarRoot
                        }}
                    >   
                        <div className={classes.title}>
                            <img className={classes.img} src={logo} alt="Логотип"/>
                        </div>
                        <div>
                            <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                size="small"
                                onClick={this.handleMenu}
                                color="inherit"
                                classes={{
                                    root: classes.buttonRoot
                                }}
                            >
                                {/* <AccountCircle /> */}
                            </IconButton>
                            <Responsive
                                small={null}
                                xsmall={null}
                                medium={
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={open}
                                        onClose={this.handleClose}
                                    >
                                        <MenuItem onClick={this.handleProfile}>
                                            <ListItemIcon style={{ color: '#00529f '}}>
                                                <PersonIcon fontSize="small" />
                                            </ListItemIcon>
                                            Профиль
                                        </MenuItem>
                                        <MenuItem onClick={this.handleLogout}>
                                        <ListItemIcon style={{ color: '#00529f '}}>
                                                <ExitToAppIcon fontSize="small" />
                                            </ListItemIcon>
                                            Выйти
                                        </MenuItem>
                                    </Menu>
                                }
                            />
                        </div>
                    </Toolbar>
                    {isLoading&&
                        <LinearProgress color="secondary" />
                    }
                </AppBar>
            </div>
        );
    }
}

const mapState = (state) => {
    const { fetch } = state;
    const isLoading = fetch ? (fetch.loading>0 ? true : false) : false;
    return { 
        isLoading,
    }
}

export default compose(
    connect(mapState),
    withRouter,
    withStyles(styles),
)(MenuAppBar);    