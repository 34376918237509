import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
const styles = {
    main: {
        width: '100%',
        margin: 0,
        border: 0,
        display: 'inline-flex',
        padding: 0,
        position: 'relative',
        minWidth: 0,
        flexDirection: 'column',
        whiteSpace: 'nowrap'
    },
    div: {
        color: 'currentColor',
        width: '100%',
        border: 0,
        margin: 0,
        display: 'block',
        boxSizing: 'content-box',
        background: 'none',
        verticalAlign: 'middle'
    },
}
const secondsToTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const mmss = new Date(totalSeconds * 1000).toISOString().substr(13, 6);
    return hours>10 ? `${hours}${mmss}` : `0${hours}${mmss}`;
}

const TimeField = ({classes, style, ...props}) =>{
    let { value, row } = props;
    if(!value){
        return <div></div>;
    }
    return (
        <div className={classes.main} style={style}>
            <div className={classes.div} style={{ color: row&&!row.Connected ? 'red' : '' }}>
                {secondsToTime(value)}
            </div>
        </div>
    )
}

export default withStyles(styles)(TimeField);
