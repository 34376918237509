import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import compose from 'recompose/compose';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';

import { SelectInput, DateRangeInput, Filter, PhoneInput } from '../_Components';

const styles = theme => ({
    form: {
        display: 'flex',
    },
    code: {
        display: 'flex',
        width: theme.spacing(10),
        marginRight: 20
    },
    search: {
        display: 'flex',
        width: theme.spacing(20),
        marginRight: 20
    },
    reports: {
        display: 'flex',
        width: theme.spacing(20),
        marginRight: 20
    },
    phone: {
        display: 'flex',
        width: theme.spacing(20),
        marginRight: 20
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
    button: {
        margin: 12,
    },
    
});

class AnalysisFilter extends Component {
    render(){
        const { classes, filterDefaultValues = {}, reports, ...props } = this.props;
        return (  
            <Filter filterValues={filterDefaultValues} {...props}>
                <DateRangeInput 
                    source="interval"
                    label={`Интервал`}
                    maxDate={moment().endOf('day')}
                    alwaysOn
                />
                {(reports&&reports.length>1)&&
                    <SelectInput 
                        source="report"
                        label={`Отчет`}
                        options={reports}
                        alwaysOn
                        className={classes.reports}
                    />
                }
                <PhoneInput 
                    source="phone"
                    label={`Номер`}
                    options={reports}
                    alwaysOn
                    className={classes.phone}
                />
            </Filter>
        );
    }
}



const mapStateToProps = (state) => {
    var recordLiveValues = getFormValues('filterForm')(state);
    return { 
        formValues: recordLiveValues,
    };
};

export default  compose(
    connect(mapStateToProps),
    withStyles(styles),
)(AnalysisFilter);