import React, { Component, Fragment } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@material-ui/core/styles';
import moment from 'moment';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import {GET, GET_ONE, provider, history } from '../_utils';

import Playlist from './components/Playlist';

export const styles = theme => createStyles({
    root: {
        height: `calc(100vh - ${theme.spacing(8)}px)`
    },
    content: {
        display: 'inline-flex',
        width: '100%',
    },
    drawerPaper: {
        width: 240,
    },
    card: {
        '& div:first-child': { height: 'intrinsic'}
    },
    cardHeaderShow: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        // backgroundColor: '#0a529f'
    },
    cardStyleRigth: {
        height: `calc(100vh - ${theme.spacing(8)}px)`,
        border: `1px solid ${theme.palette.divider}`,
        display: 'inline-block',
        boxShadow: 'unset',
        paddingRight: 0,
        paddingLeft: 0,
        marginLeft: 'auto',
        width: '100%'
    },
    cardContent: {
        paddingTop: 0,
        paddingRight: 0,
        paddingLeft: 0,
    },
    cardHeaderInfo: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        color: '#0a529f',
    },
    cardTitleShow: {
        // color: '#bbd9ea;',
        fontSize: '0.8rem;',
        fontWeight: 600
    },
    cardStyleShow: {
        height: `calc(100vh - ${theme.spacing(9)}px)`,
        border: `1px solid ${theme.palette.divider}`,
        width: '100%',
        display: 'inline-block',
        boxShadow: 'unset',
        paddingRight: 0,
        paddingLeft: 0,
        marginLeft: 'auto'
    },
    cardTitleInfo: {
        color: '#0a529f;',
        fontSize: '0.8rem;',
        fontWeight: 600
    },
    delete: {
        marginRight: theme.spacing(1),
        color: '#F44336',
        backgroundColor: '#fcedec',
        border: '1px solid #f4433685',
        '&:hover': {
            backgroundColor: '#f7c7c4',
            border: '1px solid #f44336',
        }
    },
    closeInfo: {
        border: '0px solid #f44336',
        color: "#05A1CD",
        '&:hover': {
            border: '0px solid #f44336',
            color: '#012B62',
        }
    },
    edit: {
        marginRight: theme.spacing(1),
        color: '#0a529f',
        backgroundColor: '#e2f4ff',
        border: '1px solid #0a529f59',
        '&:hover': {
            backgroundColor: '#bbd9ea',
            border: '1px solid #0a529f',
        }
    },
    info:{
        overflow: 'auto',
        position: 'relative',
        height: `calc(100vh - ${theme.spacing(20)}px)`,
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    },
    reference:{
        overflow: 'auto',
        position: 'relative',
        height: `calc(100vh - ${theme.spacing(20)}px)`,
    },
    cardTitle: {
        fontSize: '0.95rem;',
        fontWeight: 600
    },
    cardHeader: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
    },
    referenceCardContent: {
        fontSize: '0.95rem;',
    },
    callRow: {
        display: 'flex',
        margin: `${theme.spacing(1)}px 0 0 ${theme.spacing(1)}px`
    },
    callLabel: {
        marginRight: theme.spacing(1),
        fontFamily: 'Montserrat',
        fontWeight: 700,
        fontSize: '.75rem',
        color: '#012B62'
    },
    callValue: {
        fontFamily: 'Montserrat',
        fontSize: '.75rem',
        color: '#012B62'
    }
});
const  convertISO8601ToSeconds = (input) => {
    var reptms = /^PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?$/;
    var hours = 0, minutes = 0, seconds = 0, totalseconds;
  
    if (reptms.test(input)) {
        var matches = reptms.exec(input);
        if (matches[1]) hours = Number(matches[1]);
        if (matches[2]) minutes = Number(matches[2]);
        if (matches[3]) seconds = Number(matches[3]);
        totalseconds = hours * 3600  + minutes * 60 + seconds;
    }
    return (totalseconds);
  }
  
const formatDate = (input) => {
    const date = input.substring(6, 19);
    return moment(date, 'x').format('YYYY-MM-DD HH:mm:ss')
}

class Show extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tracks: [],
            call: [],
            isLoading: true,
            tab: 0,
        };
        this.close = this.close.bind(this);
    }
    
    componentDidMount(){
        if(this.props.id){
           const { callId } = history.location.search
                                                    .slice(1)
                                                    .split('&')
                                                    .map(p => p.split('='))
                                                    .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});
            this.fetchData(this.props.id);
            if(callId){
                this.fetchCallData(callId);
            }
        }
    }

    componentDidUpdate(prevProps){
        if (this.props.id !== prevProps.id) {
            this.fetchData(this.props.id);
        }
    }

    async fetchCallData(callId){
        const { data } = await provider(
            GET_ONE,
            `/call/${callId}`
        );
        if(data){
            this.setState({call: data});
        }
    }

    async fetchData(id) {
        const { data } = await provider(
            GET,
            `/visorList`,
            { id }
        );
        if(data) {
            let tracks = [];
            data.data.map( element =>{
                const track = {
                    callType: element.callType,
                    destination: element.destination,
                    duration: convertISO8601ToSeconds(element.duration),
                    id: element.id,
                    mediaFileAvailable: element.mediaFileAvailable,
                    originator: element.originator,
                    start: formatDate(element.start),
                    uid: element.uid,
                }
                return tracks.push(track);
            })
            tracks.sort((a, b) => moment(a.start).diff(moment(b.start)));
            this.setState({ tracks, isLoading: false });
        }
    }

    close (){
        const pathname = history.location.pathname.split('/');
        return history.push(`/${pathname[1]}`)
    }

    render() {
        const { tracks, isLoading, call } = this.state;
        const { classes } = this.props;
        return (
            <Fragment>
             <Card  className={classes.cardStyleRigth}>
                <CardHeader
                    classes={{
                        title: classes.cardTitleInfo,
                        root: classes.cardHeaderInfo
                    }}
                    style={{ paddingBottom: 6}}
                    action={
                        <Fragment>
                            <Button  
                                style={{
                                    padding: '0px 10px',
                                    marginTop: 6
                                }}
                                classes={{
                                    root: classes.closeInfo
                                }}                                                        
                                size="small"
                                color="secondary"
                                variant="outlined"
                                endIcon={<CloseIcon />}
                                onClick={this.close}
                            >
                                Закрыть
                            </Button>
                        </Fragment>

                    }
                />
                <CardContent classes={{ root: classes.cardContent }} >
                    <Playlist isLoading={isLoading} tracks={tracks}/>
                    <Fragment>
                        {call.map((field,key) =>
                            (<div key={key} className={classes.callRow}>
                                <div className={classes.callLabel}>{field.label}:</div>
                                <div className={classes.callValue}>{field.value}</div>
                            </div>)
                        )}
                    </Fragment>
                </CardContent>
            </Card>
            </Fragment>
        );
    }
}

const enhance = compose(
    connect(),
    withStyles(styles),
);

export default enhance(Show);
