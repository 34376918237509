import { playerConstants } from '../_constants';

const { SHOW_PLAYER, HIDE_PLAYER, ACTIVE_PLAY } = playerConstants;
const initialState = {
  id: null,
  record: null,
  state: null
}
export function player (state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case ACTIVE_PLAY:
      return { ...payload };
    case SHOW_PLAYER:
      return { ...payload };
    case HIDE_PLAYER:
      return { record: null }
    default:
      return state
  }
}