import React, {Component, Fragment} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import * as FileSaver from 'file-saver';
import AudioPlayer from 'react-h5-audio-player';

import Track from './Track';
import { authHeader } from '../../_utils';

import { GET, provider } from '../../_utils';
import { Spiner } from '../../_Components';
class Playlist extends Component {

  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
        currentTrack: null,
        getLink: true,
        uri: null,
    };
    this._add = this._add.bind(this);
    this.getLink = this.getLink.bind(this);
    this.download = this.download.bind(this);
    this.player = React.createRef();
    // this.onPlay = this.onPlay.bind(this);
  }

  _add(elem) {
    this.setState(
      { 
        uri: null,
        currentTrack: elem.id
      },
      );
  }

  async getLink(elem) {
    this.setState({ getLink: true});
    const { data } = await provider(
        GET,
        '/visor/media',
        {
            id: elem.id,
            uid: elem.uid
        }
    );
    if( data && this._isMounted){
        this.setState({
            uri: '/api/visor/play/'+data.fileName,
            getLink: false
        });
    }
  }


  async download(elem){
    const { data } = await provider( 
        GET,
        '/visor/media', 
        {
            id: elem.id,
            uid: elem.uid
        }
    );
    if( data ){
      const response = await fetch(`/api/visor/play/${data.fileName}`, {
          method: 'get',
          responseType: 'arraybuffer',
          headers: {...authHeader(), 'Content-Type': 'application/json'},
      })
      this.setState({ isSaving: false });
      response.arrayBuffer().then(function(buffer) {
          var blob = new Blob([buffer], {type: "audio/mpeg"});
          FileSaver.saveAs(blob, `${data.fileName}`);
      });
    }
  }

  componentDidUpdate(prev){
      if(this.props.tracks!==prev.tracks){
          this.props.tracks.map((track) => {
              return this.getLink(track);
          });
      }
  }

  componentDidMount(){
      this._isMounted = true;
  }

  componentWillUnmount() {
    //this.player.current.audio.current.pause();
    this.setState({ isMounted: false });
  }
  onPause(){
  }

  onPlay(){
  }

  render() {
    const { uri, currentTrack, getLink } = this.state;
    let tracks = this.props.tracks.map((track, i) => {
      return <Track
                  track={track}
                  key={'track_' + track.id}
                  index={i}
                  currentTrack={currentTrack}
                  play={this._add}
                  download={this.download}
                />;
    });


    return  (
      <Fragment>
        {!getLink ?
          <Fragment>
              <AudioPlayer
                  ref = { this.player }
                  src={uri}
                  showFilledVolume={true}
                  preload='auto'
                  autoPlay={false}
                  showLoopControl={false}
                  autoPlayAfterSrcChange = {false}
                  onPlay={this.onPlay}
                  onPause={this.onPause}
                  showDownloadProgress={true}
                  showJumpControls={false}
                  layout="horizontal"
                  customAdditionalControls={[]}
              />
            
            <Table>
                <TableBody>
                      {tracks}
                </TableBody>
            </Table>
          </Fragment>
          :
          <Spiner />
        }
      </Fragment>
    )
  }
}

export default Playlist;
