import React, { Fragment, useState, useEffect} from 'react';
import {withStyles, createStyles} from '@material-ui/core/styles';
import moment from 'moment';
import compose from 'recompose/compose';
import {connect} from 'react-redux'
import {getFormValues} from 'redux-form';
import { reports } from '../_utils';
import Grid from '@mui/material/Grid';

import {
    GET,
    provider
} from '../_utils';

import Filter from './Filter';
import Tasks from './componets/tasks';
import Servicelevel from './componets/servicelevel';
import Calls from './componets/calls';
import Results from './componets/results';

const minHeight = 300;
const height = 35;

const styles = theme =>
    createStyles({
        main: {
            height: `calc(${height}vh)`,
            paddingLeft: theme.spacing(3), 
            minHeight,
            borderBottom: '0.5px solid #BDBDBD' 
        },
        conteiner: {
            marginTop: `${theme.spacing(.5)}px !important`
        },
        canvasItems: {
            borderLeft: '0.5px solid #BDBDBD',
            paddingTop: '0px !important'
        },
        blockLabel: {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: theme.spacing(2),
            color: "#05A1CD",
        },
        card2: {
            height: `calc((${height}vh - ${theme.spacing(7)}px) / 2)`,
            minHeight: `calc((${minHeight}px - ${theme.spacing(7)}px) / 2)`,
            marginTop: theme.spacing(1),
            position: 'relative'
        },
        slLabel:{
            fontFamily: 'Montserrat',
            fontSize: 12,
            color: '#0b2b61',
            height: `calc((${height}vh - ${theme.spacing(7)}px) / 2)`
        },
        valuesSL: {
            position: 'absolute',
            bottom: 0,
            right: theme.spacing(2),
            height: theme.spacing(6),
            display: 'flex',
            color: '#109618',
            '& .MuiTypography-root': {
                fontWeight: 600
            }
        },
        valuesAR: {
            position: 'absolute',
            bottom: 0,
            right: theme.spacing(2),
            height: theme.spacing(6),
            display: 'flex',
            color: '#dc3912',
            '& .MuiTypography-root': {
                fontWeight: 600
            }
        },
        chartBlock: {
            height: `calc(${height}vh - ${theme.spacing(7)}px)`,
            // minHeight: `calc((${minHeight}px - ${theme.spacing(7)}px)`,
            paddingRight: theme.spacing(2)
        },
        chartGrid: {
          height: "100%"
        },
        card4: {
            height: `calc((${height}vh - ${theme.spacing(9)}px) / 4)`,
            minHeight: `calc((${minHeight}px - ${theme.spacing(9)}px) / 4)`,
            marginTop: theme.spacing(1),
            position: 'relative'
        },
        callsLabel: {
            fontFamily: 'Montserrat',
            fontSize: 12,
            color: '#0b2b61',
            height: `calc((${height}vh - ${theme.spacing(7)}px) / 4)`
        },
        valuesCalls: {
            position: 'absolute',
            bottom: 0,
            right: theme.spacing(2),
            height: theme.spacing(4),
            display: 'flex',
            color: '#0b2b61',
            '& .MuiTypography-root': {
                fontWeight: 600
            }
        },
        taskItem: {
            paddingTop: `${theme.spacing(3)}px !important`,
            minHeight: minHeight,
            height: `calc(${100-height}vh - ${theme.spacing(25)}px)`,
        },
        resultItem: {
            // borderBottom: '0.5px solid #BDBDBD',
            paddingTop: `${theme.spacing(3)}px !important`,
            minHeight: minHeight,
            height: `calc(${100-height}vh - ${theme.spacing(25)})`,
            paddingRight: theme.spacing(3)
        },
        resultLabel: {
            textAlign: "-webkit-center",
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: theme.spacing(2),
            color: "#05A1CD",
        },
        resultCard: {
            height: theme.spacing(10)
        },
        resultContent: {
            padding: `${theme.spacing(.5)}px ${theme.spacing(1)}px`
        },
        resultCardLabel: {
            fontFamily: 'Montserrat',
            fontSize: 12,
            color: '#0b2b61',
            height: theme.spacing(4)
        },
        resultGrid: {
            paddingTop: theme.spacing(.5)
        },
        resultBlock: {
            display: 'flex',
            marginTop: 'auto'
        },
        resultCount: {
            alignContent: 'flex-start'
        },
        resultPercent: {
            marginLeft: 'auto',
            alignContent: 'flex-end'
        }
    });

const Dashboard = (props) => {
    const { isLoading, formValues, classes } = props;
    const [reportsList] = useState(reports());
    const defaultValue = {
        report: formValues ? formValues.report : reportsList[0]._id,
        interval: {
            startDate: formValues ? formValues.interval.startDate : moment().subtract(30, 'days').startOf('day'),
            endDate: formValues ? formValues.interval.endDate : moment().endOf('day')
        }
    }
    const [results, setResults] = useState({});
    const [calls, setCalls] = useState([]);
    const [summary, setSummary] = useState({});
    const [isLoadingResults, setLoadingResults] = useState(true);
    const [isLoadingCalls, setLoadingCalls] = useState(true);
    const [activeReportId, setActiveReportId] = useState(formValues ? formValues.report : reportsList[0]._id);
    const [settings, setSettings] = useState(reportsList.find( r=> r._id === activeReportId).dashboard);
    const [filterValues,setFilterValues] = useState();

    const getCallsReport = async (filter) => {
        setLoadingCalls(true)
        const { data } = await provider(
            GET,
            '/client/callsStat',
            {
                filter: {
                    report: filter.report,
                    interval: {
                        startDate: filter.interval.startDate.format("YYYY-MM-DD"),
                        endDate: filter.interval.endDate.format("YYYY-MM-DD")
                    }
                },
                sort: {field: 'time', order: "ASC"},
                pagination: {page: 1, perPage: 999},
            }
        );
        if (data) {
            const { calls, summary } = data.data;
            setCalls(calls);
            setSummary(summary);
            setLoadingCalls(false)
        }
    }

    const getResultsReport = async (filter) => {
        setLoadingResults(true);
        const {data} = await provider(
            GET,
            '/client/resultsStat',
            {
                filter: {
                    report: filter.report,
                    interval: {
                        startDate: filter.interval.startDate.format("YYYY-MM-DD"),
                        endDate: filter.interval.endDate.format("YYYY-MM-DD")
                    }
                }, sort: {field: 'time', order: "ASC"},
                pagination: {page: 1, perPage: 999},
            }
        );
        if (data) {
            setResults(data);
            setLoadingResults(false)
        }
    }

    useEffect( () => {
        if(filterValues){
            getCallsReport(filterValues);
            getResultsReport(filterValues);
        }
    },[filterValues])

    // useEffect( () => {
    //     console.log(isInitial);
    //     if(filterValues){
    //         const activeReport = reportsList.find( report =>  report._id === filterValues.report);
    //         if(activeReport&&isInitial){
    //             setInitial(false);
    //             setSettings(activeReport.dashboard);
    //         }
    //     }
    // },[filterValues, reportsList, isInitial])

    useEffect( () => {
        if(filterValues){
            const activeReport = reportsList.find( report =>  report._id === filterValues.report);
            if(activeReport&&filterValues.report!==activeReportId){
                setActiveReportId(activeReport._id)
                setSettings(activeReport.dashboard);
            }
        }
    },[filterValues, reportsList, activeReportId])
    
    return (
        <Fragment>
            <Filter
                reports={reportsList}
                initialValues={defaultValue}
                setFilters={setFilterValues}
                // fetchData={fetchData}
                isLoading={isLoading}
            />
            <Grid container spacing={1} alignItems="stretch" className={classes.main}> {/*style={{}}*/}
                <Grid container item alignItems="flex-start" spacing={3} xs={12} className={classes.conteiner}>
                    {settings.sl&&
                        <Servicelevel summary={summary} isLoading={isLoadingCalls} calls={calls} classes={classes} minHeight={minHeight} settings={settings}/>
                    }
                    <Calls isLoading={isLoadingCalls} calls={calls} classes={classes} minHeight={minHeight} settings={settings}/>
                </Grid>
                <Grid container spacing={1} >
                    <Grid item  xs={2}>
                        <Results isLoading={isLoadingResults} results={results.results} classes={classes} />
                    </Grid>
                    <Grid item xs={10} >
                        <Tasks isLoading={isLoadingResults} data={results.tasks || { data: null, keys: null}}  classes={classes}/>
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    );
}

const mapStateToProps = (state) => {
    const {fetch} = state;
    var recordLiveValues = getFormValues('dashboardForm')(state);
    return {
        formValues: recordLiveValues,
        isLoading: !!fetch.loading
    };
};
export default compose(
    withStyles(styles),
    connect(mapStateToProps),
)(Dashboard);